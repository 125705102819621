import { Map, OrderedSet } from 'immutable';
import { handleSuccess } from '../lib/reduxUtils';
import {
	FETCH_TICKETS, DELETE_TICKET, LOGOUT, CREATE_TICKET
} from '../actions/types';

const ticketsPaginationDataInitialState = {
	pagination: Map(),
	total_pages: null
};

export const ticketsPaginationData = ( state = ticketsPaginationDataInitialState, action ) => {
	switch ( action.type ) {
	case CREATE_TICKET:
		return handleSuccess(
			state,
			action,
			prevState => ( {
				...prevState,
				pagination: prevState.pagination.update(
					1,
					( set = OrderedSet( [ action.payload.id ] ) ) => set.add( action.payload.id )
				),
				total_pages: prevState.total_pages || 1
			} )
		);
	case FETCH_TICKETS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { meta, data } = action.payload;
				return {
					...prevState,
					pagination: prevState.pagination.set(
						meta.current_page,
						OrderedSet( data.map( ticket => ticket.id ) )
					),
					total_pages: meta.total_pages
				};
			}
		);
	case DELETE_TICKET:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { id } = action.meta;
				const page = prevState.pagination.findKey( value => ( value.has( id ) ) );
				const newTicketIds = prevState.pagination.get( Number( page ) );
				return {
					...prevState,
					pagination: prevState.pagination.set(
						page,
						newTicketIds.delete( id )
					)
				};
			}
		);
	case LOGOUT:
		return ticketsPaginationDataInitialState;
	default:
		return state;
	}
};
