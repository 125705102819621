import { lazy } from 'react';
import { useSelector } from 'react-redux';
import { faReceipt, faHome } from '@fortawesome/pro-regular-svg-icons';
import { getNotificationsUnseenCount } from '../selectors/notificationsUnseenCount';

export default [
	{
		component: lazy( () => import( '../screens/Login/Login' ) ),
		path: '/login',
		private: false
	},
	{
		component: lazy( () => import( '../screens/Apps/Apps' ) ),
		path: '/dashboard',
		private: true,
		isInsideNav: true,
		icon: faHome,
		text: 'SideNav.dashboard',
		notifications: () => 0
	},
	{
		component: lazy( () => import( '../screens/ForgotPassword/ForgotPassword' ) ),
		path: '/forgot-password',
		private: false
	},
	{
		component: lazy( () => import( '../screens/ResetPassword/ResetPassword' ) ),
		path: '/reset-password',
		private: false
	},
	{
		component: lazy( () => import( '../screens/Tickets/Tickets' ) ),
		path: '/tickets',
		private: true,
		isInsideNav: true,
		icon: faReceipt,
		text: 'SideNav.tickets',
		notifications: () => useSelector( getNotificationsUnseenCount )
	},
	{
		component: lazy( () => import( '../screens/TicketDetail/TicketDetail' ) ),
		path: '/tickets/:id',
		private: true
	},
	{
		component: lazy( () => import( '../screens/AppDetail/AppDetail' ) ),
		path: '/dashboard/:id',
		private: true
	},
	// Keep NotFound always at the bottom
	{
		component: lazy( () => import( '../screens/NotFound/NotFound' ) ),
		path: '*',
		private: false
	}
];
