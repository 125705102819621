import { normalize } from 'normalizr';

export const normalizeResponse = (
	response, scheme, collectionName
) => {
	const entities = normalize(
		response,
		Array.isArray( response ) ? [ scheme ] : scheme
	).entities[ collectionName ] || [];

	return Object
		.values( entities )
		.map( serialization => [ serialization.id, serialization ] );
};
