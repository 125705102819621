import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Loading } from '@theamalgama/carbon-components-react';
import { useSelector } from 'react-redux';
import CustomRoute from './routing/CustomRoute';
import routes from './routing/routes';
import { getHasToShowLoader } from './selectors/loader';

export const App = () => {
	const hasToShowLoader = useSelector( getHasToShowLoader );
	return (
		<>
			{hasToShowLoader && <Loading />}
			<Suspense fallback={<Loading />}>
				<Switch>
					<Redirect exact from="/" to="/dashboard" />
					{
						routes.map( route => (
							<CustomRoute
								exact
								key={route.path}
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...route}
							/>
						) )
					}
				</Switch>
			</Suspense>
		</>
	);
};

export default withRouter( App );
