import { SET_CURRENT_LOCALE } from '../actions/types';

export const currentLocale = ( state = null, action ) => {
	switch ( action.type ) {
	case SET_CURRENT_LOCALE:
		return action.payload;
	default:
		return state;
	}
};
