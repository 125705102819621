export const attributesTypes = [ 'type', 'topic', 'priority' ];

export default class TicketAttribute {
	constructor( {
		id,
		label,
		type
	} ) {
		this.id = id;
		this.label = label;
		this.type = type;
	}

	static fromJSON( properties ) {
		return new TicketAttribute( {
			id: properties.id,
			label: properties.label,
			type: properties.type
		} );
	}

	toJSON() {
		return {
			id: this.id,
			label: this.label,
			type: this.type
		};
	}
}
