export const DEFAULT_LANGUAGE = 'en';

//notification locations

export const NOTIFICATION_PLACE_DASHBOARD = 'Dashboard';
export const NOTIFICATION_PLACE_FORM_MODAL = 'FormModal';

//media sizes

export const MAX_TOTAL_MEDIA_SIZE = 20 * 1024 * 1024;
export const MAX_INDIVIDUAL_MEDIA_SIZE = 2 * 1024 * 1024;
export const COMMENTS_PER_PAGE_DEFAULT = 10;
export const COMMENTS_PAGE_DEFAULT = 1;
export const ACCEPTED_FILE_EXTENSIONS = ['image/jpeg', 'image/png'];

//editor

export const MAX_CHARACTERS_IN_EDITOR = 300;

//kinds of buttons 

export const KINDS_OF_BUTTONS = ['ghost', 'primary', 'secondary', 'tertiary', 'danger'];

//ticket table

export const TICKET_NOT_SHOWN_HEADERS = ['seen', 'class_name'];
export const TICKET_DESKTOP_CONTENT_HEADERS = [];
export const TICKET_TABLET_CONTENT_HEADERS = ['created_at', 'updated_at', 'type', 'topic', 'assigned', 'priority'];
export const TICKET_MOBILE_CONTENT_HEADERS = ['created_at', 'updated_at', 'type', 'id', 'assigned', 'topic', 'status', 'priority'];

export const TICKET_HEADERS_DATA = [
    {key: 'id', header: 'TicketTableContainer.header.id', sortKey: 'id'},
    {key: 'instance', header: 'TicketTableContainer.header.instance', sortKey: 'system_instances:instance_id|system_instances.name'},
    {key: 'assigned', header: 'TicketTableContainer.header.assigned', sortKey: 'account_users:assigned_id|account_users.first_name;account_users.last_name'},
    {key: 'topic', header: 'TicketTableContainer.header.topic', sortKey: 'topic_id'},
    {key: 'title', header: 'TicketTableContainer.header.title', sortKey: 'title'},
    {key: 'status', header: 'TicketTableContainer.header.status', sortKey: 'status'},
    {key: 'priority', header: 'TicketTableContainer.header.priority', sortKey: 'priority_id'},
    {key: 'seen', header: 'TicketTableContainer.header.seen'},
    {key: 'class_name', header: 'TicketTableContainer.header.class_name'},
    {key: 'type', header: 'TicketTableContainer.header.type', sortKey: 'type_id'},
    {key: 'created_at', header: 'TicketTableContainer.header.created_at', sortKey: 'created_at'},
    {key: 'updated_at', header: 'TicketTableContainer.header.updated_at', sortKey: 'updated_at'},
    {key: 'actions', header: 'TicketTableContainer.header.actions'}
];

export const APP_DETAIL_TICKETS_HEADERS_DATA = [
    {key: 'id', header: 'TicketTableContainer.header.id', sortKey: 'id'},
    {key: 'assigned', header: 'TicketTableContainer.header.assigned', sortKey: 'account_users:assigned_id|account_users.first_name;account_users.last_name'},
    {key: 'topic', header: 'TicketTableContainer.header.topic', sortKey: 'topic_id'},
    {key: 'title', header: 'TicketTableContainer.header.title', sortKey: 'title'},
    {key: 'status', header: 'TicketTableContainer.header.status', sortKey: 'status'},
    {key: 'priority', header: 'TicketTableContainer.header.priority', sortKey: 'priority_id'},
    {key: 'seen', header: 'TicketTableContainer.header.seen'},
    {key: 'class_name', header: 'TicketTableContainer.header.class_name'},
    {key: 'type', header: 'TicketTableContainer.header.type', sortKey: 'type_id'},
    {key: 'created_at', header: 'TicketTableContainer.header.created_at', sortKey: 'created_at'},
    {key: 'updated_at', header: 'TicketTableContainer.header.updated_at', sortKey: 'updated_at'},
    {key: 'actions', header: 'TicketTableContainer.header.actions'}
];

//apps table

export const APP_HEADERS_DATA = [
    {key: 'id', header: 'AppsTableContainer.header.id'},
    {key: 'name', header: 'AppsTableContainer.header.name', sortKey: 'name'},
    {key: 'countryNames', header: 'AppsTableContainer.header.countryNames'},
    {key: 'cityNames', header: 'AppsTableContainer.header.cityNames'},
    {key: 'companyName', header: 'AppsTableContainer.header.companyName'},
    {key: 'class_name', header: 'TicketTableContainer.header.class_name'},
    {key: 'email', header: 'AppsTableContainer.header.email'},
    {key: 'website', header: 'AppsTableContainer.header.website'},
    {key: 'appIOSUrl', header: 'AppsTableContainer.header.appIOSUrl'},
    {key: 'appAndroidUrl', header: 'AppsTableContainer.header.appAndroidUrl'},
    {key: 'instanceUrl', header: 'AppsTableContainer.header.instanceUrl'},
    {key: 'actions', header: 'AppsTableContainer.header.actions'}
];

export const APP_NOT_SHOWN_HEADERS = ['seen', 'class_name', 'id'];
export const APP_DESKTOP_CONTENT_HEADERS = ['companyName', 'email', 'website', 'appIOSUrl', 'appAndroidUrl', 'instanceUrl'];
export const APP_TABLET_CONTENT_HEADERS = ['companyName', 'email', 'website', 'appIOSUrl', 'appAndroidUrl', 'instanceUrl'];
export const APP_MOBILE_CONTENT_HEADERS = ['countryNames', 'cityNames', 'companyName', 'email', 'website', 'appIOSUrl', 'appAndroidUrl', 'instanceUrl'];;

// TABLES
export const APP_TABLE = 'APP';
export const TICKET_TABLE = 'TICKET';
export const TABLES_HEADERS = {
    APP: {
        DESKTOP_CONTENT_HEADERS: APP_DESKTOP_CONTENT_HEADERS,
        TABLET_CONTENT_HEADERS: APP_TABLET_CONTENT_HEADERS,
        MOBILE_CONTENT_HEADERS: APP_MOBILE_CONTENT_HEADERS,
    },
    TICKET: {
        DESKTOP_CONTENT_HEADERS: TICKET_DESKTOP_CONTENT_HEADERS,
        TABLET_CONTENT_HEADERS: TICKET_TABLET_CONTENT_HEADERS,
        MOBILE_CONTENT_HEADERS: TICKET_MOBILE_CONTENT_HEADERS,
    }
}

export const SCREENS = ['isTablet', 'isMobile', 'isDesktop'];
