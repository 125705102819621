import { handleSuccess } from '../lib/reduxUtils';
import { LOGIN, LOGOUT } from '../actions/types';

const INITIAL_STATE = null;

export const currentUser = ( state = INITIAL_STATE, action ) => {
	switch ( action.type ) {
	case LOGIN:
		return handleSuccess(
			state,
			action,
			() => action.payload
		);
	case LOGOUT:
		return INITIAL_STATE;
	default:
		return state;
	}
};
