import { schema } from 'normalizr';

export const user = new schema.Entity( 'users' );

export const attribute = new schema.Entity( 'attributes' );

export const country = new schema.Entity( 'countries' );

export const city = new schema.Entity( 'cities', {
	country
} );

export const app = new schema.Entity( 'apps', {
	cities: [ city ]
} );

export const comment = new schema.Entity( 'comments', {
	commenter: user
} );

export const ticket = new schema.Entity( 'tickets', {
	reporter: user,
	assigned: user,
	topic: attribute,
	priority: attribute,
	type: attribute,
	comments: [ comment ],
	instance: app
} );

comment.define( {
	commentable: ticket
} );

country.define( {
	cities: [ city ]
} );
