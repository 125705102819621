import { OrderedSet, Map } from 'immutable';
import { handleSuccess } from '../lib/reduxUtils';
import {
	CREATE_COMMENT, FETCH_COMMENTS, DELETE_TICKET, DELETE_COMMENT, LOGOUT
} from '../actions/types';

const addCommentsToTicket = ( state, ticketId, commentIds ) => state.update(
	ticketId,
	currentIds => (
		currentIds
			? OrderedSet( commentIds ).concat( currentIds )
			: OrderedSet( commentIds ) )
);

const INITIAL_STATE = Map();

export const ticketComments = ( state = INITIAL_STATE, action ) => {
	switch ( action.type ) {
	case CREATE_COMMENT:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { ticketId } = action.meta;
				return addCommentsToTicket( prevState, Number( ticketId ), [ action.payload.id ] );
			}
		);
	case FETCH_COMMENTS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { ticketId } = action.meta;
				const commentIds = action.payload.map( comment => comment.id );
				return addCommentsToTicket( prevState, Number( ticketId ), commentIds );
			}
		);
	case DELETE_TICKET:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { id } = action.meta;
				return prevState.delete( Number( id ) );
			}
		);
	case DELETE_COMMENT:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { ticketId, commentId } = action.meta;
				if ( prevState.has( Number( ticketId ) ) ) {
					return prevState.update(
						ticketId, currentIds => currentIds.filter(
							id => id !== commentId
						)
					);
				}
				return prevState;
			}
		);
	case LOGOUT:
		return INITIAL_STATE;
	default:
		return state;
	}
};
