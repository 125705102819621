class ApiLanguageUpdater {
	constructor( store, api ) {
		this.store = store;
		this.api = api;

		this._unsubscribeFromStore = null;
		this._onStoreUpdated = this._onStoreUpdated.bind( this );
	}

	start() {
		this._unsubscribeFromStore = this.store.subscribe( this._onStoreUpdated );
		return this;
	}

	stop() {
		if ( this._unsubscribeFromStore ) { this._unsubscribeFromStore(); }
		return this;
	}

	_onStoreUpdated() {
		const { currentLocale } = this.store.getState();
		if ( !currentLocale ) {
			this.api.headers.language = localStorage.getItem( 'i18nextLng' );
		} else {
			this.api.headers.language = currentLocale;
		}
	}
}

export default ApiLanguageUpdater;
