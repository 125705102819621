/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { getUserToken } from '../selectors/currentUser';

const CustomRoute = ( { component: Component, private: authenticatedRoute, ...rest } ) => {
	const currentUserToken = useSelector( getUserToken );
	const redirectionCondition = authenticatedRoute ? currentUserToken : !currentUserToken;

	return (
		<Route
			{...rest}
			render={props => ( redirectionCondition
				? <Component {...props} />
				: <Redirect to={{ pathname: authenticatedRoute ? '/login' : '/dashboard', state: { from: props.location } }} /> )}
		/>
	);
};

CustomRoute.propTypes = {
	location: PropTypes.shape( { pathname: PropTypes.string } ),
	private: PropTypes.bool,
	component: PropTypes.elementType.isRequired
};

CustomRoute.defaultProps = {
	location: {
		pathname: '/'
	},
	private: false
};

export default CustomRoute;
