/* eslint-disable camelcase */
export default class Country {
	constructor( {
		id,
		name,
		alpha2,
		countryCode,
		hasInstance,
		cities = []
	} ) {
		this.id = id;
		this.name =	name;
		this.alpha2 = alpha2;
		this.countryCode = countryCode;
		this.hasInstance = hasInstance;
		this.cities = cities;
	}

	static fromJSON( {
		id,
		name,
		alpha_2: alpha2,
		country_code: countryCode,
		hasInstance
	}, cities ) {
		return new Country( {
			id,
			name,
			alpha2,
			countryCode,
			hasInstance,
			cities
		} );
	}

	toJSON() {
		return {
			id: this.id,
			name: this.name,
			alpha_2: this.alpha2,
			country_code: this.countryCode,
			hasInstance: this.hasInstance,
			cities: this.cities
		};
	}
}
