import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import Api from '../services/api/Api';
import ApiAuthTokenUpdater from '../services/api/ApiAuthTokenUpdater';
import ApiLanguageUpdater from '../services/api/ApiLanguageUpdater';
import loggerMiddleware from './middlewares/logger';
import createRouteReducer from '../reducers';

export const history = createBrowserHistory();

const api = new Api();

const middlewares = [
	routerMiddleware( history ),
	thunk.withExtraArgument( api ),
	reduxPackMiddleware
];

if ( process.env.NODE_ENV === 'development' ) {
	middlewares.push( loggerMiddleware );
}

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [
		'currentUser',
		'currentLocale'
	]
};

const persistedReducer = persistReducer(
	persistConfig,
	createRouteReducer( history )
);

export const store = createStore(
	persistedReducer,
	applyMiddleware( ...middlewares )
);

export const persistor = persistStore( store );
export const authTokenUpdater = new ApiAuthTokenUpdater( store, api ).start();
export const languageUpdater = new ApiLanguageUpdater( store, api ).start();
