import React from 'react';
import PropTypes from 'prop-types';

import './TableRowWithImg.scss';

const TableRowWithImg = ( { logoUrl, text } ) => (
	<div className="TableRowWithImg">
		<img src={logoUrl} alt="logo" />
		<span>{text}</span>
	</div>
);

TableRowWithImg.propTypes = {
	logoUrl: PropTypes.string,
	text: PropTypes.string
};

TableRowWithImg.defaultProps = {
	logoUrl: '',
	text: ''
};

export default TableRowWithImg;
