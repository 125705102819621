import {
	SHOW_NOTIFICATION, RESET_NOTIFICATION, LOGOUT
} from '../actions/types';

export const notification = ( state = null, action ) => {
	switch ( action.type ) {
	case SHOW_NOTIFICATION:
		return action.payload;
	case RESET_NOTIFICATION:
		return null;
	case LOGOUT:
		return null;
	default:
		return state;
	}
};
