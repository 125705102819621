import { createSelector } from 'reselect';
import User from '../entities/user';

const getCurrentUserFromState = state => state.currentUser;

export const getUserToken = createSelector(
	[ getCurrentUserFromState ],
	currentUser => ( currentUser ? currentUser.token : undefined )
);

export const getCurrentUserId = createSelector(
	[ getCurrentUserFromState ],
	currentUser => ( currentUser ? currentUser.id : undefined )
);

export const getCurrentUserRole = createSelector(
	[ getCurrentUserFromState ],
	currentUser => ( currentUser?.role?.slug )
);

export const getCurrentUser = createSelector(
	[ getCurrentUserFromState ],
	currentUser => User.fromJSON( currentUser )
);
