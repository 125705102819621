import { createSelector } from 'reselect';
import TicketAttribute from '../entities/ticketAttribute';

export const getAttributesFromState = state => state.attributes;

export const getTypeAttributes = createSelector(
	[ getAttributesFromState ],
	attributes => attributes
		.filter( attribute => attribute.type === 'type' )
		.map( attribute => TicketAttribute.fromJSON( attribute ) )
);

export const getTopicAttributes = createSelector(
	[ getAttributesFromState ],
	attributes => attributes
		.filter( attribute => attribute.type === 'topic' )
		.map( attribute => TicketAttribute.fromJSON( attribute ) )
);

export const getPriorityAttributes = createSelector(
	[ getAttributesFromState ],
	attributes => attributes
		.filter( attribute => attribute.type === 'priority' )
		.map( attribute => TicketAttribute.fromJSON( attribute ) )
);

export const getTopicAttributesArray = createSelector(
	[ getTopicAttributes ],
	topics => topics.valueSeq().toArray()
);

export const getPriorityAttributesArray = createSelector(
	[ getPriorityAttributes ],
	priorities => priorities.valueSeq().toArray()
);

export const getMappedAttributes = createSelector(
	[
		getTypeAttributes,
		getTopicAttributes,
		getPriorityAttributes
	],
	( types, topics, priorities ) => ( {
		topic: topics,
		type: types,
		priority: priorities
	} )
);
