import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import * as authReducers from './authentication';
import * as currentUserReducers from './currentUser';
import * as localeReducers from './locales';
import * as ticketsReducer from './tickets';
import * as ticketsPaginationDataReducer from './ticketsPaginationData';
import * as attributesReducer from './attributes';
import * as usersReducer from './users';
import * as notificationReducer from './notifications';
import * as commentsReducer from './comments';
import * as ticketComments from './ticketComments';
import * as notificationsUnseenCount from './notificationsUnseenCount';
import * as appsReducer from './apps';
import * as appsPaginationDataReducer from './appsPaginationData';
import * as citiesReducer from './cities';
import * as countriesReducer from './countries';
import * as appTicketsReducer from './appTickets';


export default history => combineReducers( {
	router: connectRouter( history ),
	...authReducers,
	...currentUserReducers,
	...localeReducers,
	...ticketsReducer,
	...ticketsPaginationDataReducer,
	...attributesReducer,
	...usersReducer,
	...notificationReducer,
	...commentsReducer,
	...ticketComments,
	...notificationsUnseenCount,
	...appsReducer,
	...appsPaginationDataReducer,
	...citiesReducer,
	...countriesReducer,
	...appTicketsReducer
} );
