export default class City {
	constructor( {
		id,
		name,
		hasInstance,
		country = undefined
	} ) {
		this.id = id;
		this.name =	name;
		this.hasInstance = hasInstance;
		this.country = country;
	}

	static fromJSON( {
		id,
		name,
		hasInstance
	}, country ) {
		return new City( {
			id,
			name,
			hasInstance,
			country
		} );
	}

	toJSON() {
		return {
			id: this.id,
			name: this.name,
			hasInstance: this.hasInstance,
			country: this.country
		};
	}
}
