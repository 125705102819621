/* eslint-disable camelcase */
export default class Ticket {
	constructor( {
		id,
		topic,
		title,
		priority,
		assigned,
		description,
		status,
		created_at,
		updated_at,
		type,
		seen,
		reporter,
		instance,
		gallery
	} ) {
		this.id = id;
		this.topic = topic;
		this.title = title;
		this.priority = priority;
		this.assigned = assigned;
		this.description = description;
		this.status = status;
		this.created_at = created_at;
		this.updated_at = updated_at;
		this.type = type;
		this.seen = seen;
		this.reporter = reporter;
		this.instance = instance;
		this.gallery = gallery;
	}

	static fromJSON( {
		id,
		title,
		description,
		status,
		seen,
		created_at,
		updated_at,
		gallery
	}, assigned, topic, priority, type, reporter, instance ) {
		return new Ticket( {
			id,
			topic,
			title,
			priority,
			assigned,
			description,
			status,
			seen,
			created_at,
			updated_at,
			type,
			reporter,
			instance,
			gallery
		} );
	}

	toJSON() {
		return {
			id: this.id,
			topic: this.topic,
			type: this.type,
			title: this.title,
			priority: this.priority,
			assigned: this.assigned,
			reporter: this.reporter,
			description: this.description,
			status: this.status,
			seen: this.seen,
			created_at: this.created_at,
			updated_at: this.updated_at,
			instance: this.instance,
			gallery: this.gallery
		};
	}

	canBeEditedBy( userId ) {
		return this.reporter && this.reporter.id === userId;
	}

	canBeDeleted() {
		return [ 'cancelled', 'closed' ].includes( this.status );
	}

	static canBeDeletedBy( user ) {
		return user.canDeleteTickets();
	}

	static canBeCreatedBy( user ) {
		return user.canCreateTickets();
	}
}
