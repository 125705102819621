import { Map } from 'immutable';
import { makeAsyncActionReducer, handleSuccess } from '../lib/reduxUtils';
import {
	FETCH_TICKETS,
	CREATE_TICKET,
	RESET_CREATE_TICKET,
	FETCH_TICKET_AND_FETCH_COMMENTS,
	EDIT_TICKET_AND_FETCH_COMMENTS,
	DELETE_TICKET,
	LOGOUT,
	DELETE_APP
} from '../actions/types';
import { ticket as ticketSchema } from './schemas';
import { normalizeResponse } from '../lib/normalizrUtils';

export const fetchTicketRequest = makeAsyncActionReducer( FETCH_TICKET_AND_FETCH_COMMENTS );
export const fetchTicketsRequest = makeAsyncActionReducer( FETCH_TICKETS );
export const createTicketRequest = makeAsyncActionReducer( CREATE_TICKET, RESET_CREATE_TICKET );
export const editTicketRequest = makeAsyncActionReducer( EDIT_TICKET_AND_FETCH_COMMENTS );
export const deleteTicketRequest = makeAsyncActionReducer( DELETE_TICKET );

const normalizeTickets = actionPayload => normalizeResponse(
	actionPayload, ticketSchema, 'tickets'
);

const INITIAL_STATE = Map();

export const tickets = ( state = INITIAL_STATE, action ) => {
	switch ( action.type ) {
	case FETCH_TICKETS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const normalizedTickets = normalizeTickets( action.payload.data );
				return prevState.merge( Map( normalizedTickets ) );
			}
		);
	case FETCH_TICKET_AND_FETCH_COMMENTS:
	case EDIT_TICKET_AND_FETCH_COMMENTS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const normalizedTicket = normalizeTickets( action.payload );
				return prevState.merge( Map( normalizedTicket ) );
			}
		);
	case CREATE_TICKET:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const normalizedTicket = normalizeTickets( action.payload );
				return prevState.merge( Map( normalizedTicket ) );
			}
		);
	case DELETE_TICKET:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { id } = action.meta;
				return prevState.delete( Number( id ) );
			}
		);
	case DELETE_APP:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { id } = action.meta;
				return prevState.filter( value => id === value.instance.id );
			}
		);
	case LOGOUT:
		return INITIAL_STATE;
	default:
		return state;
	}
};
