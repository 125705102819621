import { createSelector } from 'reselect';
import { getIsEditingOrFetchingOrDeletingOrCreatingTicketRequest } from './tickets';
import { getIsFetchingOrCreatingOrDeletingComment } from './comments';
import { getIsFetchingOrCreatingOrDeletingApp } from './apps';

export const getHasToShowLoader = createSelector(
	[
		getIsEditingOrFetchingOrDeletingOrCreatingTicketRequest,
		getIsFetchingOrCreatingOrDeletingComment,
		getIsFetchingOrCreatingOrDeletingApp
	],
	(
		isEditingOrFetchingOrDeletingOrCreatingTicket,
		isFetchingOrCreatingOrDeletingComment,
		isFetchingOrCreatingOrDeletingApp
	) => isEditingOrFetchingOrDeletingOrCreatingTicket
		|| isFetchingOrCreatingOrDeletingComment
		|| isFetchingOrCreatingOrDeletingApp
);
