import React from 'react';
import { createSelector } from 'reselect';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import Ticket from '../entities/ticket';
import {
	getTopicAttributes,
	getPriorityAttributes,
	getTypeAttributes
} from './attributes';
import { getAppsFromState } from './apps';
import Tag from '../components/Tag/Tag';
import PriorityLabel from '../components/PriorityLabel/PriorityLabel';
import { getUsersFromState } from './users';

const getTicketsFromState = state => state.tickets;

const createTicketRequest = state => state.createTicketRequest;
const editTicketRequest = state => state.editTicketRequest;
const fetchTicketRequest = state => state.fetchTicketRequest;
const deleteTicketRequest = state => state.deleteTicketRequest;
const fetchTicketsRequest = state => state.fetchTicketsRequest;

export const getIsEditingOrFetchingOrDeletingOrCreatingTicketRequest = createSelector(
	[
		fetchTicketRequest,
		fetchTicketsRequest,
		editTicketRequest,
		deleteTicketRequest,
		createTicketRequest
	],
	( fetchTicket, fetchTickets, editTicket, deleteTicket, createTicket ) => fetchTicket.sending
        || fetchTickets.sending
		|| editTicket.sending
		|| deleteTicket.sending
		|| createTicket.sending
);

export const getTicketsList = createSelector(
	[
		getTicketsFromState,
		getTopicAttributes,
		getPriorityAttributes,
		getTypeAttributes,
		getUsersFromState,
		getAppsFromState
	],
	( tickets, topics, priorities, types, users, apps ) => tickets
		.map( ( ticket ) => {
			const topic = topics.get( ticket.topic );
			const priority = priorities.get( ticket.priority );
			const type = types.get( ticket.type );
			const assigned = users.get( ticket.assigned ) || null;
			const reporter = users.get( ticket.reporter ) || null;
			const app = apps.get( ticket.instance ) || null;
			return Ticket.fromJSON(
				ticket,
				assigned,
				topic,
				priority,
				type,
				reporter,
				app
			);
		} )
);

export const getTicket = createSelector(
	[
		( _, props ) => props.id,
		getTicketsList
	],
	( id, tickets ) => tickets.get( Number( id ) )
);

export const getTicketsInPage = createSelector(
	[
		( _, props ) => props,
		getTicketsList
	],
	( { ids, userCanDelete, onItemDeleteSelection }, ticketList ) => ids
		.map( ( id ) => {
			const ticket = ticketList.get( id );
			return {
				id: ticket.id.toString(),
				instance: ticket.instance ? ticket.instance.name : '',
				title: ticket.title,
				created_at: moment( ticket.created_at ).format( 'DD/MM/YYYY' ),
				updated_at: moment( ticket.updated_at ).format( 'DD/MM/YYYY' ),
				assigned: ticket.assigned ? ticket.assigned.full_name : '',
				priority: <PriorityLabel stage={ticket.priority.label} />,
				status: <Tag type={ticket.status} />,
				type: ticket.type.label,
				seen: ticket.seen,
				topic: ticket.topic.label,
				class_name: ticket.seen ? 'seen' : 'not-seen',
				actions: ticket.canBeDeleted() && userCanDelete
				&& <FontAwesomeIcon icon={faTrash} onClick={() => onItemDeleteSelection( ticket )} />
			};
		} ).toArray()
);
