import { Map } from 'immutable';
import { makeAsyncActionReducer, handleSuccess } from '../lib/reduxUtils';
import {
	FETCH_ATTRIBUTES
} from '../actions/types';
import { normalizeResponse } from '../lib/normalizrUtils';
import { attribute as attributeSchema } from './schemas';

export const fetchAttributesRequest = makeAsyncActionReducer( FETCH_ATTRIBUTES );

const normalizeAttributes = actionPayload => normalizeResponse(
	actionPayload, attributeSchema, 'attributes'
);

export const attributes = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_ATTRIBUTES:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const newAttributes = normalizeAttributes( action.payload );
				return prevState.merge( Map( newAttributes ) );
			}
		);
	default:
		return state;
	}
};
