import { makeAsyncActionReducer } from '../lib/reduxUtils';
import {
	LOGIN,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	RESET_RESET_PASSWORD
} from '../actions/types';

export const loginRequest = makeAsyncActionReducer( LOGIN );
export const forgotPasswordRequest = makeAsyncActionReducer( FORGOT_PASSWORD );
export const resetPasswordRequest = makeAsyncActionReducer( RESET_PASSWORD, RESET_RESET_PASSWORD );
