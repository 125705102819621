class ApiResponse {
	constructor( response, json ) {
		this.response = response;
		this.json = json;

		this.jsonError = this.jsonError.bind( this );
	}

	jsonError() {
		if ( this.json.errors ) {
			return Object.entries( this.json.errors )[ 0 ][ 1 ][ 0 ].description;
		} if ( this.json.type === 'error' ) {
			return this.json.description;
		}
		return '';
	}

	static fromFetchResponse( response ) {
		if ( !response.json ) {
			return Promise.resolve( response );
		}
		return response.json()
			.then(
				json => new ApiResponse( response, json )
			)
			.catch(
				() => new ApiResponse( response, undefined )
			);
	}

	get status() { return this.response.status; }

	get statusText() { return this.response.statusText; }

	get description() { return this.jsonError(); }

	get headers() { return this.response.headers; }

	get isOk() {
		return this.response.ok
		|| this.response.status === 304
		|| this.response.status === 200;
	}
}

export default ApiResponse;
