import React from 'react';
import {
	Tag as TagCarbon
} from '@theamalgama/carbon-components-react';
import PropTypes from 'prop-types';

import './Tag.scss';

const types = [
	'open',
	'resolved',
	'cancelled',
	'closed'
];

const colours = {
	'open': 'blue',
	'resolved': 'green',
	'cancelled': 'red',
	'closed': 'gray'
};

const Tag = ( { type } ) => (
	<TagCarbon className="Tag" type={colours[ type ]}>
		{ type[ 0 ].toUpperCase() + type.substring( 1 ) }
	</TagCarbon>
);

Tag.propTypes = {
	type: PropTypes.oneOf( types )
};

Tag.defaultProps = {
	type: 'open'
};

export default Tag;
