import { Map } from 'immutable';
import { handleSuccess } from '../lib/reduxUtils';
import { country as countrySchema } from './schemas';
import { normalizeResponse } from '../lib/normalizrUtils';
import {
	FETCH_APPS,
	FETCH_COUNTRIES_WITH_CITIES,
	LOGOUT
} from '../actions/types';

const normalizeCountries = actionPayload => normalizeResponse(
	actionPayload, countrySchema, 'countries'
);

const INITIAL_STATE = Map();

export const countries = ( state = INITIAL_STATE, action ) => {
	switch ( action.type ) {
	case FETCH_APPS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const countriesData = action.payload.data.map(
					app => app.cities.map(
						city => ( { ...city.country, cities: [ city.id ] } )
					)
				).flat();
				const arrayHashmap = countriesData.reduce( ( accum, currentCountry ) => {
					const oldCities = prevState.has( currentCountry.id )
						? prevState.get( currentCountry.id ).cities
						: [];
					if ( accum[ currentCountry.id ] ) {
						accum[ currentCountry.id ].cities = [
							...new Set( [
								...oldCities,
								...accum[ currentCountry.id ].cities,
								...currentCountry.cities
							] )
						];
					} else {
						accum[ currentCountry.id ] = { ...currentCountry };
					}
					return accum;
				}, {} );
				const countriesWithMergedCities = Object.values( arrayHashmap );

				const normalizedCountries = normalizeCountries(
					countriesWithMergedCities
				);

				return prevState.merge( Map( normalizedCountries ) );
			}
		);
	case FETCH_COUNTRIES_WITH_CITIES:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const normalizedCountries = normalizeCountries( action.payload );
				return prevState.merge( Map( normalizedCountries ) );
			}
		);
	case LOGOUT:
		return INITIAL_STATE;
	default:
		return state;
	}
};
