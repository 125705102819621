import ApiHeaders from './ApiHeaders';
import ApiResponse from './ApiResponse';

export const defaultFetchBackend = fetch.bind( window );

export default class Api {
	constructor( authRefreshTokenURL, fetchBackend = defaultFetchBackend ) {
		this.fetch = fetchBackend;
		this.headers = new ApiHeaders();

		this.authRefreshTokenURL = authRefreshTokenURL;
		this.onAuthenticationTokenUpdated = undefined;
		this._authenticationToken = undefined;
		this._refreshingTokenPromise = undefined;
	}

	// Authentication
	get authenticationToken() { return this._authenticationToken; }

	set authenticationToken( value ) {
		this._authenticationToken = value;

		this.authorization = this._authenticationToken
			? `Bearer ${this._authenticationToken.access_token}`
			: undefined;
	}

	get authorization() { return this.headers.authorization; }

	set authorization( value ) { this.headers.authorization = value; }

	// HTTP methods
	get( url, headers = {} ) {
		return this._request( 'GET', url, undefined, headers );
	}

	post( url, body = undefined, headers = {} ) {
		return this._request( 'POST', url, body, headers );
	}

	patch( url, body = undefined, headers = {} ) {
		return this._request( 'PATCH', url, body, headers );
	}

	put( url, body = undefined, headers = {} ) {
		return this._request( 'PUT', url, body, headers );
	}

	delete( url, body = undefined, headers = {} ) {
		return this._request( 'DELETE', url, body, headers );
	}

	// Private
	static _isExpiredTokenError( error ) {
		return error.json && error.json.status === 401;
	}

	_options( method, body = undefined, requestHeaders = {} ) {
		const headers = {
			...this.headers.toDictionary(),
			...requestHeaders
		};

		const options = { method, headers };

		if ( body instanceof FormData ) {
			delete headers[ 'Content-Type' ];
		}

		if ( body ) {
			options.body = headers[ 'Content-Type' ] === 'application/json'
				? JSON.stringify( body )
				: body;
		}

		return options;
	}

	_request(
		method,
		url,
		body = undefined,
		headers = {},
		shouldRefreshToken = true
	) {
		return this
			.fetch( url, this._options( method, body, headers ) )
			.then( response => ApiResponse.fromFetchResponse( response ) )
			.then( ( apiResponse ) => {
				if ( !apiResponse.isOk ) { throw apiResponse; }
				return apiResponse;
			} )
			.catch( async ( err ) => {
				if (
					Api._isExpiredTokenError( err )
					&& this._authenticationToken
					&& shouldRefreshToken
				) {
					return this
						._refreshToken()
						.catch( () => {
							this.authenticationToken = undefined;
							if ( this.onAuthenticationTokenUpdated ) {
								this.onAuthenticationTokenUpdated( undefined );
							}

							throw err;
						} )
						.then( ( response ) => {
							this.authenticationToken = response.json.data;
							if ( this.onAuthenticationTokenUpdated ) {
								this.onAuthenticationTokenUpdated( this.authenticationToken );
							}

							return this._request( method, url, body, headers );
						} );
				}

				if ( err instanceof ApiResponse ) {
					throw err;
				}
				const apiResponseError = await ApiResponse.fromFetchResponse( err );
				throw apiResponseError;
			} );
	}

	_refreshToken() {
		if ( this._refreshingTokenPromise ) {
			return this._refreshingTokenPromise;
		}

		this._refreshingTokenPromise = this
			._request(
				'POST',
				this.authRefreshTokenURL,
				{ refresh_token: this._authenticationToken.refresh_token },
				{},
				false
			)
			.then(
				( result ) => {
					this._refreshingTokenPromise = undefined;
					return result;
				}
			)
			.catch( ( err ) => {
				this._refreshingTokenPromise = undefined;
				throw err;
			} );

		return this._refreshingTokenPromise;
	}
}
