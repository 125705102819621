/* eslint-disable camelcase */
export default class App {
	constructor( {
		id,
		name,
		key,
		secret,
		companyName,
		companyWebsite,
		email,
		phone,
		appAndroidUrl,
		appIOSUrl,
		instanceUrl,
		cities,
		createdAt,
		updatedAt,
		logo
	} ) {
		this.id = id;
		this.name =	name;
		this.key =	key;
		this.secret =	secret;
		this.companyName =	companyName;
		this.companyWebsite =	companyWebsite;
		this.email =	email;
		this.phone =	phone;
		this.appAndroidUrl =	appAndroidUrl;
		this.appIOSUrl =	appIOSUrl;
		this.instanceUrl =	instanceUrl;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.cities = cities;
		this.logo = logo;
	}

	static fromJSON( {
		id,
		name,
		key,
		secret,
		company_name: companyName,
		company_website: companyWebsite,
		contact_email: email,
		contact_phone: phone,
		url_android: appAndroidUrl,
		url_ios: appIOSUrl,
		url: instanceUrl,
		created_at: createdAt,
		updated_at: updatedAt,
		logo
	}, cities ) {
		return new App( {
			id,
			name,
			key,
			secret,
			companyName,
			companyWebsite,
			email,
			phone,
			appAndroidUrl,
			appIOSUrl,
			instanceUrl,
			createdAt,
			updatedAt,
			cities,
			logo
		} );
	}

	toJSON() {
		return {
			id: this.id,
			name: this.name,
			key: this.key,
			secret: this.secret,
			company_name: this.companyName,
			company_website: this.companyWebsite,
			contact_email: this.email,
			contact_phone: this.phone,
			url_android: this.appAndroidUrl,
			url_ios: this.appIOSUrl,
			url: this.instanceUrl,
			created_at: this.createdAt,
			updated_at: this.updatedAt,
			cities: this.cities,
			logo: this.logo
		};
	}

	static canBeDeletedBy( user ) {
		return user.canDeleteApps();
	}

	static canBeCreatedBy( user ) {
		return user.canCreateApps();
	}
}
