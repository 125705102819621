import { handleSuccess } from '../lib/reduxUtils';
import { FETCH_UNSEEN_NOTIFICATIONS } from '../actions/types';

export const notificationsUnseenCount = ( state = null, action ) => {
	switch ( action.type ) {
	case FETCH_UNSEEN_NOTIFICATIONS:
		return handleSuccess(
			state,
			action,
			() => action.payload
		);
	default:
		return state;
	}
};
