import { Map, OrderedSet } from 'immutable';
import {
	CREATE_APP,
	CREATE_TICKET,
	DELETE_APP,
	DELETE_TICKET,
	FETCH_TICKET,
	FETCH_TICKETS,
	LOGOUT
} from '../actions/types';
import { handleSuccess } from '../lib/reduxUtils';

const INITIAL_STATE = Map();

const addTicketsToApp = ( state, appId, ticketId ) => state.update(
	appId, value => ( value ? value.add( ticketId ) : OrderedSet() )
);

export const appTickets = ( state = INITIAL_STATE, action ) => {
	switch ( action.type ) {
	case CREATE_TICKET:
		return handleSuccess(
			state,
			action,
			prevState => addTicketsToApp( prevState, action.payload.instance.id, action.payload.id )
		);
	case FETCH_TICKETS:
		return handleSuccess(
			state,
			action,
			prevState => action.payload.data.reduce(
				( accum, currentTicket ) => addTicketsToApp(
					accum,
					currentTicket.instance.id,
					currentTicket.id
				),
				prevState
			)
		);
	case FETCH_TICKET:
		return handleSuccess(
			state,
			action,
			prevState => addTicketsToApp( prevState, action.payload.instance.id, action.payload.id )
		);
	case CREATE_APP:
		return handleSuccess(
			state,
			action,
			prevState => prevState.set( action.payload.id, OrderedSet() )
		);
	case DELETE_APP:
		return handleSuccess(
			state,
			action,
			prevState => prevState.delete( action.payload.id )
		);
	case DELETE_TICKET:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { id } = action.meta;
				const appId = prevState.findKey( value => value.has( id ) );
				return prevState.update( appId, value => value.delete( id ) );
			}
		);
	case LOGOUT:
		return INITIAL_STATE;
	default:
		return state;
	}
};
