/* eslint-disable camelcase */
export default class Comment {
	constructor( {
		id,
		body,
		likes_quantity,
		user_has_liked,
		created_at,
		updated_at,
		commenter,
		gallery
	} ) {
		this.id = id;
		this.created_at = created_at;
		this.updated_at = updated_at;
		this.gallery = gallery;
		this.likes_quantity = likes_quantity;
		this.user_has_liked = user_has_liked;
		this.body = body;
		this.commenter = commenter;
	}

	static fromJSON( {
		id,
		created_at,
		updated_at,
		gallery,
		likes_quantity,
		user_has_liked,
		body
	}, commenter ) {
		return new Comment( {
			id,
			created_at,
			updated_at,
			gallery,
			likes_quantity,
			user_has_liked,
			body,
			commenter
		} );
	}

	toJSON() {
		return {
			id: this.id,
			created_at: this.created_at,
			updated_at: this.updated_at,
			gallery: this.gallery,
			likes_quantity: this.likes_quantity,
			user_has_liked: this.user_has_liked,
			body: this.body,
			commenter: this.commenter
		};
	}

	isOwnedBy( userId ) {
		return this.commenter.id === userId;
	}
}
