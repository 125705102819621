// Authentication

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_RESET_PASSWORD = 'RESET_RESET_PASSWORD';

// LOCALES

export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';

// TICKETS

export const FETCH_TICKETS = 'FETCH_TICKETS';
export const FETCH_TICKET = 'FETCH_TICKET';
export const CREATE_TICKET = 'CREATE_TICKET';
export const RESET_CREATE_TICKET = 'RESET_CREATE_TICKET';
export const MARK_TICKET_AS_SEEN = 'MARK_TICKET_AS_SEEN';
export const FETCH_TICKET_DATA = 'FETCH_TICKET_DATA';
export const EDIT_TICKET = 'EDIT_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';

// ATTRIBUTES

export const FETCH_ATTRIBUTES = 'FETCH_ATTRIBUTES';

// USERS

export const FETCH_REVIEWERS = 'FETCH_REVIEWERS';

// NOTIFICATION

export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

// COMMENTS

export const CREATE_COMMENT = 'CREATE_COMMENT';
export const RESET_CREATE_COMMENT = 'RESET_CREATE_COMMENT';
export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const DELETE_COMMENT = 'DELETE_COMMENT';

// COMMENTS AND TICKET

export const EDIT_TICKET_AND_FETCH_COMMENTS = 'EDIT_TICKET_AND_FETCH_COMMENTS';
export const FETCH_TICKET_AND_FETCH_COMMENTS = 'FETCH_TICKET_AND_FETCH_COMMENTS';

// UNSEEN_NOTIFICATIONS_COUNT

export const FETCH_UNSEEN_NOTIFICATIONS = 'UNSEEN_NOTIFICATIONS_COUNT';

// APPS

export const FETCH_APPS = 'FETCH_APPS';
export const CREATE_APP = 'CREATE_APP';
export const DELETE_APP = 'DELETE_APP';
export const FETCH_APP = 'FETCH_APP';

// COUNTRIES AND CITIES

export const FETCH_COUNTRIES_WITH_CITIES = 'FETCH_COUNTRIES_WITH_CITIES';
