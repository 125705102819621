import { Map } from 'immutable';
import { handleSuccess } from '../lib/reduxUtils';
import { city as citySchema } from './schemas';
import { normalizeResponse } from '../lib/normalizrUtils';
import {
	FETCH_APPS,
	FETCH_COUNTRIES_WITH_CITIES,
	LOGOUT
} from '../actions/types';

const normalizeCities = actionPayload => normalizeResponse(
	actionPayload, citySchema, 'cities'
);

const INITIAL_STATE = Map();

export const cities = ( state = INITIAL_STATE, action ) => {
	switch ( action.type ) {
	case FETCH_APPS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const normalizedCities = normalizeCities(
					action.payload.data.map( app => app.cities ).flat()
				);
				return prevState.merge( Map( normalizedCities ) );
			}
		);
	case FETCH_COUNTRIES_WITH_CITIES:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const normalizedCities = normalizeCities(
					action.payload.map(
						country => country.cities.map( city => ( { ...city, country: country.id } ) )
					).flat()
				);
				return prevState.merge( Map( normalizedCities ) );
			}
		);
	case LOGOUT:
		return INITIAL_STATE;
	default:
		return state;
	}
};
