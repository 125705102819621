import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './PriorityLabel.scss';

const stages = [
	'Medium',
	'Low',
	'High'
];

const PriorityLabel = ( { stage } ) => {
	const priorityLabelClassNames = classNames( 'PriorityLabel', stage );
	return (
		<div className={priorityLabelClassNames}>
			<span className="dot" />
			{ stage }
		</div>
	);
};

PriorityLabel.propTypes = {
	stage: PropTypes.oneOf( stages )
};

PriorityLabel.defaultProps = {
	stage: 'Medium'
};

export default PriorityLabel;
