import { createSelector } from 'reselect';
import User from '../entities/user';

export const getUsersFromState = state => state.users;

export const getReviewersFromState = createSelector(
	[ getUsersFromState ],
	users => users
		.map( user => User.fromJSON( user ) )
		.filter( user => user.role.slug === 'support_staff' || user.role.slug === 'admin' )
);

export const getReviewersFromStateArray = createSelector(
	[ getReviewersFromState ],
	users => users.valueSeq().toArray()
);

export const getReviewersFromStateArrayWithUnassigned = createSelector(
	[ getReviewersFromStateArray ],
	users => users.concat( User.fromJSON( {
		full_name: 'Unassigned',
		id: '0'
	} ) )
);
