import { Map } from 'immutable';
import { handleSuccess, makeAsyncActionReducer } from '../lib/reduxUtils';
import {
	CREATE_COMMENT, RESET_CREATE_COMMENT, FETCH_COMMENTS, DELETE_TICKET, DELETE_COMMENT, LOGOUT
} from '../actions/types';
import { comment as commentSchema } from './schemas';
import { normalizeResponse } from '../lib/normalizrUtils';

const normalizeComments = actionPayload => normalizeResponse(
	actionPayload, commentSchema, 'comments'
);

export const createCommentRequest = makeAsyncActionReducer( CREATE_COMMENT, RESET_CREATE_COMMENT );
export const deleteCommentRequest = makeAsyncActionReducer( DELETE_COMMENT );
export const fetchCommentsRequest = makeAsyncActionReducer( FETCH_COMMENTS );

const INITIAL_STATE = Map();

export const comments = ( state = INITIAL_STATE, action ) => {
	switch ( action.type ) {
	case CREATE_COMMENT:
	case FETCH_COMMENTS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const normalizedComments = normalizeComments( action.payload );
				return prevState.merge( Map( normalizedComments ) );
			}
		);
	case DELETE_TICKET:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { id } = action.meta;
				return prevState.filter(
					value => Number( value.commentable ) !== Number( id )
				);
			}
		);
	case DELETE_COMMENT:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { commentId } = action.meta;
				return prevState.delete( commentId );
			}
		);
	case LOGOUT:
		return INITIAL_STATE;
	default:
		return state;
	}
};
