import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import fr from './locales/fr.json';
import en from './locales/en.json';

import { DEFAULT_LANGUAGE } from './config/global';

const resources = {
	en,
	fr
};

i18n
	.use( LanguageDetector )
	.use( initReactI18next )
	.init( {
		resources,
		lng: DEFAULT_LANGUAGE,
		debug: process.env.NODE_ENV !== 'production',
		detection: {
			lookupQueryString: 'lng'
		}
	} );


export default i18n;
