import { OrderedSet } from 'immutable';
import { createSelector } from 'reselect';
import Comment from '../entities/comment';
import { getUsersFromState } from './users';

const getCommentsFromState = state => state.comments;
const getCommentsFromTicketState = state => state.ticketComments;

const createCommentsRequest = state => state.createCommentRequest;
const deleteCommentRequest = state => state.deleteCommentRequest;
const fetchCommentsRequest = state => state.fetchCommentsRequest;

export const getIsFetchingOrCreatingOrDeletingComment = createSelector(
	[
		createCommentsRequest,
		deleteCommentRequest,
		fetchCommentsRequest
	],
	( createRequest, deleteRequest, fetchRequest ) => createRequest.sending
	|| deleteRequest.sending
	|| fetchRequest.sending
);

export const getCommentsList = createSelector(
	[
		getCommentsFromState,
		getUsersFromState
	],
	( comments, users ) => comments.map( ( comment ) => {
		const user = users.get( comment.commenter );
		return Comment.fromJSON(
			comment,
			user
		);
	} )
);

export const getComments = createSelector(
	[
		( _, props ) => props.ticketId,
		getCommentsFromTicketState,
		getCommentsList
	],
	( ticketId, ticketComments, comments ) => {
		const commentIds = ticketComments.get( Number( ticketId ) ) || OrderedSet();
		const commentsOrdered = commentIds
			.map( id => comments.get( id ) )
			.filter( value => value )
			.toArray()
			.reverse();
		return commentsOrdered;
	}
);
