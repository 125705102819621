import { createSelector } from 'reselect';
import Country from '../entities/country';
import City from '../entities/city';

export const getCitiesFromState = state => state.cities;

export const getCountriesFromState = state => state.countries;

export const getCitiesList = createSelector(
	[
		getCitiesFromState,
		getCountriesFromState
	],
	( cities, countries ) => cities.map( ( city ) => {
		const cityCountry = Country.fromJSON( countries.get( city.country ) );
		return City.fromJSON( city, cityCountry );
	} )
);

export const getCountriesList = createSelector(
	[
		getCountriesFromState,
		getCitiesFromState
	],
	( countries, cities ) => countries.map( ( country ) => {
		const countryCities = country.cities.map( city => City.fromJSON( cities.get( city ) ) );
		return Country.fromJSON( country, countryCities );
	} ) );

export const getCountriesListOrdered = createSelector(
	[
		getCountriesList
	],
	countries => countries.sort(
		( firstCountry, secondCountry ) => {
			if ( firstCountry.name > secondCountry.name ) {
				return 1;
			} if ( firstCountry.name < secondCountry.name ) {
				return -1;
			}
			return 0;
		}
	)
);

export const getCountriesWithInstances = createSelector(
	[
		getCountriesListOrdered
	],
	countries => countries
		.filter( country => country.hasInstance )
		.map( country => ( { ...country, cities: country.cities.filter( city => city.hasInstance ) } ) )
		.valueSeq()
		.toArray()
);
