/* eslint-disable camelcase */
export default class User {
	constructor( {
		id, full_name, email, avatar, role
	} ) {
		this.id = id;
		this.full_name = full_name;
		this.email = email;
		this.avatar = avatar;
		this.role = role;
	}

	static fromJSON( properties ) {
		return new User( {
			id: properties.id,
			full_name: properties.full_name,
			email: properties.email,
			avatar: properties.avatar,
			role: properties.role
		} );
	}

	toJSON() {
		return {
			id: this.id,
			full_name: this.full_name,
			email: this.email,
			avatar: this.avatar,
			role: this.role
		};
	}

	canDeleteTickets() {
		return [ 'admin', 'sysadmin' ].includes( this.role.slug );
	}

	canCreateTickets() {
		return [ 'admin', 'sysadmin' ].includes( this.role.slug );
	}

	canDeleteApps() {
		return [ 'admin', 'sysadmin' ].includes( this.role.slug );
	}

	canCreateApps() {
		return [ 'admin', 'sysadmin' ].includes( this.role.slug );
	}
}
