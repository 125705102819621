import { Map } from 'immutable';
import { handleSuccess, makeAsyncActionReducer } from '../lib/reduxUtils';
import { app as appSchema } from './schemas';
import { normalizeResponse } from '../lib/normalizrUtils';
import {
	DELETE_APP,
	FETCH_APPS,
	CREATE_APP,
	LOGOUT,
	FETCH_APP
} from '../actions/types';

export const fetchAppsRequest = makeAsyncActionReducer( FETCH_APPS );
export const fetchAppRequest = makeAsyncActionReducer( FETCH_APP );
export const createAppRequest = makeAsyncActionReducer( CREATE_APP );
export const deleteAppRequest = makeAsyncActionReducer( DELETE_APP );

const normalizeApps = actionPayload => normalizeResponse(
	actionPayload, appSchema, 'apps'
);

const INITIAL_STATE = Map();

export const apps = ( state = INITIAL_STATE, action ) => {
	switch ( action.type ) {
	case FETCH_APPS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const normalizedApps = normalizeApps( action.payload.data );
				return prevState.merge( Map( normalizedApps ) );
			}
		);
	case FETCH_APP:
	case CREATE_APP:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const normalizedApps = normalizeApps( action.payload );
				return prevState.merge( Map( normalizedApps ) );
			}
		);
	case DELETE_APP:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const { id } = action.meta;
				return prevState.delete( id );
			}
		);
	case LOGOUT:
		return INITIAL_STATE;
	default:
		return state;
	}
};
