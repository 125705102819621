import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { createSelector } from 'reselect';
import TableRowWithImg from '../components/TableRowWithImg/TableRowWithImg';
import App from '../entities/app';
import { getCitiesList } from './countriesCities';

export const getAppsFromState = state => state.apps;

export const getFetchAppsRequest = state => state.fetchAppsRequest;
export const getFetchAppRequest = state => state.fetchAppRequest;
export const getCreateAppRequest = state => state.createAppRequest;
export const getDeleteAppRequest = state => state.deleteAppRequest;

export const getIsFetchingOrCreatingOrDeletingApp = createSelector(
	[
		getFetchAppsRequest,
		getFetchAppRequest,
		getCreateAppRequest,
		getDeleteAppRequest
	],
	(
		fetchApps,
		fetchApp,
		createApp,
		deleteApp
	) => fetchApps.sending || fetchApp.sending || createApp.sending || deleteApp.sending
);

export const getAppsList = createSelector(
	[
		getAppsFromState,
		getCitiesList
	],
	( apps, stateCities ) => apps.map( ( app ) => {
		const appCities = app.cities.map( city => stateCities.get( city ) );
		return App.fromJSON( app, appCities );
	} )
);

export const getAppsListArray = createSelector(
	[
		getAppsList
	],
	apps => apps.valueSeq().toArray()
);

export const getApp = createSelector(
	[
		( _, props ) => props.id,
		getAppsList
	],
	( id, apps ) => apps.get( Number( id ) ) );

export const getAppsInPage = createSelector(
	[
		( _, props ) => props,
		getAppsList
	],
	( { ids, userCanDelete, onItemDeleteSelection }, appList ) => ids.map( ( id ) => {
		const app = appList.get( id );
		return {
			id: app.id.toString(),
			name: <TableRowWithImg text={app.name} logoUrl={app.logo.url} />,
			countryNames: [ ...new Set( app.cities.map( city => city.country.name ) ) ].join( ', ' ),
			cityNames: app.cities.map( city => city.name ).join( ', ' ),
			companyName: app.companyName,
			email: app.email,
			website: app.companyWebsite,
			appIOSUrl: app.appIOSUrl,
			appAndroidUrl: app.appAndroidUrl,
			instanceUrl: app.instanceUrl,
			class_name: 'not-seen',
			actions: userCanDelete
				? (
					<FontAwesomeIcon
						icon={faTrash}
						onClick={() => onItemDeleteSelection( app )}
					/>
				)
				: undefined
		};
	} ).toArray()
);
