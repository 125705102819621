import { handle } from 'redux-pack';

export const initialState = {
	sending: false, error: null, success: false
};

const createAsyncActionState = ( sending, error, success ) => ( { sending, error, success } );

export const reduceAsyncAction = ( state, action ) => handle( state, action, {
	// eslint-disable-next-line no-unused-vars
	start: _ => createAsyncActionState(
		true, null, false
	),
	failure: ( _, { payload } ) => createAsyncActionState(
		false, payload, false
	),
	// eslint-disable-next-line no-unused-vars
	success: _ => createAsyncActionState(
		false, null, true
	)
} );

// eslint-disable-next-line max-len
export const handleSuccess = ( state, action, handler ) => handle( state, action, { success: handler } );

export const makeAsyncActionReducer = ( actionType, resetActionType = undefined ) => (
	state = initialState, action
) => {
	if ( !resetActionType ) {
		resetActionType = `RESET_${actionType}`;
	}

	const { type } = action;
	switch ( type ) {
	case actionType:
		return reduceAsyncAction( state, action );
	case resetActionType:
		return initialState;
	default:
		return state;
	}
};

export const entitiesMapForPayload = ( payload, entityClass, parserMethodName = 'fromJSON' ) => payload.map(
	properties => entityClass[ parserMethodName ]( properties )
);

export const entitiyMapForPayload = (
	payload,
	entityClass,
	previousValues = {}
) => entityClass.fromJSON( { ...previousValues, ...payload } );
