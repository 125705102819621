import { Map } from 'immutable';
import { makeAsyncActionReducer, handleSuccess } from '../lib/reduxUtils';
import {
	FETCH_REVIEWERS, FETCH_TICKETS, FETCH_TICKET, FETCH_COMMENTS
} from '../actions/types';
import { ticket as ticketSchema, user as userSchema } from './schemas';
import { normalizeResponse } from '../lib/normalizrUtils';

export const fetchReviewersRequest = makeAsyncActionReducer( FETCH_REVIEWERS );

const normalizeSchema = ( actionPayload, schema ) => normalizeResponse(
	actionPayload, schema, 'users'
);

export const users = ( state = Map(), action ) => {
	switch ( action.type ) {
	case FETCH_REVIEWERS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const newReviewers = normalizeSchema( action.payload, userSchema );
				return prevState.merge( Map( newReviewers ) );
			}
		);
	case FETCH_TICKETS:
	case FETCH_TICKET:
	case FETCH_COMMENTS:
		return handleSuccess(
			state,
			action,
			( prevState ) => {
				const newUsers = normalizeSchema( action.payload, ticketSchema );
				return prevState.merge( Map( newUsers ) );
			}
		);
	default:
		return state;
	}
};
